<template>
	<div class="detailsMass">
		<p class="title">老年人生活自理能力评估表</p>
		<el-table :data="tableData" border style="width: 100%">
			<el-table-column prop="title" width="165"> </el-table-column>
			<el-table-column prop="notes"> </el-table-column>
			<el-table-column prop="score" width="350">
				<template slot-scope="scope">
					<p v-if="scope.row.title == '进餐'">
						{{
							scope.row.score == 0
								? '独立完成；'
								: scope.row.score == 3
								? '需要协助，如切碎、搅拌食物等；'
								: scope.row.score == 5
								? '完全需要帮助；'
								: ''
						}}{{ scope.row.score + '分' }}
					</p>
					<p v-if="scope.row.title == '梳洗'">
						{{
							scope.row.score == 0
								? '独立完成；'
								: scope.row.score == 3
								? '在协助下和适当的时间内，能完成部分梳洗活动；'
								: scope.row.score == 7
								? '完全需要帮助；'
								: '能独立地洗头、梳头、洗脸、刷牙、剃须等；洗澡需要协助；'
						}}{{ scope.row.score + '分' }}
					</p>
					<p v-if="scope.row.title == '穿衣'">
						{{
							scope.row.score == 0
								? '独立完成；'
								: scope.row.score == 3
								? '需要协助，在适当的时间内完成部分穿衣；'
								: scope.row.score == 5
								? '完全需要帮助；'
								: ''
						}}{{ scope.row.score + '分' }}
					</p>
					<p v-if="scope.row.title == '如厕'">
						{{
							scope.row.score == 0
								? '独立完成；'
								: scope.row.score == 1
								? '偶尔失禁，但基本上能如厕或使用便具；'
								: scope.row.score == 5
								? '经常失禁，在很多提示和协助下尚能如厕或使用便具；'
								: '完全失禁，完全需要帮助；'
						}}{{ scope.row.score + '分' }}
					</p>
					<p v-if="scope.row.title == '活动'">
						{{
							scope.row.score == 0
								? '独立完成；'
								: scope.row.score == 1
								? '借助较小的外力或辅助装置能完成站立、行走、上下楼梯等；'
								: scope.row.score == 5
								? '借助较大的外力才能完成站立、行走，不能上下楼梯；'
								: '卧床不起，活动完全需要帮助；'
						}}{{ scope.row.score + '分' }}
					</p>
					<p v-if="scope.row.title == '总得分'">
						{{ scope.row.score + '分' }}
					</p>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
export default {
	data() {
		return {
			tableData: [
				{
					title: '进餐',
					notes: '使用餐具将饭菜送入口、咀嚼、吞咽等活动',
					score: 0,
				},
				{
					title: '梳洗',
					notes: '梳头、洗脸、刷牙、剃须、洗澡等活动',
					score: 0,
				},
				{
					title: '穿衣',
					notes: '穿衣裤、袜子、鞋子等活动',
					score: 0,
				},
				{
					title: '如厕',
					notes: '小便、大便等活动及自控',
					score: 0,
				},
				{
					title: '活动',
					notes: '站立、室内行走、上下楼梯、户外活动',
					score: 0,
				},
				{
					title: '总得分',
					notes: '0～3分者为可自理；4～8分者为轻度依赖；9～18分者为中度依赖；19分者为不能自理。',
					score: 0,
				},
			],
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			this.$http
				.get(this.$route.query.link)
				.then((res) => {
					if (res.data && res.data.success) {
						let data = res.data.collection[0] || {};
						this.tableData[0].score = data.mealScore || 0;
						this.tableData[1].score = data.washScore || 0;
						this.tableData[2].score = data.dressingScore || 0;
						this.tableData[3].score = data.toiletteScore || 0;
						this.tableData[4].score = data.walkingScore || 0;
						this.tableData[5].score = data.totalScore || 0;
					}
				})
				.catch((err) => {});
		},
	},
};
</script>

<style lang="scss" scoped>
.detailsMass {
	background-color: #fff;
	border-radius: 2px;
	width: 80%;
	margin: 16px auto;
	padding: 24px 40px 40px;
	.title {
		font-size: 16px;
		color: #333333;
		padding-left: 8px;
		border-left: 3px solid #1db9b1;
		line-height: 16px;
		margin-bottom: 16px;
	}
	/deep/ .el-table {
		thead {
			display: none;
		}
		td {
			color: #333;
			border-color: #d6d9e0;
		}
		tr:hover {
			> td:not(:first-child) {
				background-color: transparent !important;
			}
		}
		tbody {
			tr {
				td:first-child {
					background-color: #f4f6fd;
				}
			}
		}
	}
}
</style>